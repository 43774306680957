<template>
  <div class="background-img-4 flx flx-d-column fx-center h-100">
    <div class="title">
      <h2 class="step">4º PASSO</h2>
      <h2 class>Título do Nugget</h2>
    </div>
    <div class="fx-center flx-d-column">
      <input type="text" name="inputTitle" id="input-insta" value="@" v-model="inputTitle" />
      <div class="fx-center">
        <button class="btn-yellow" type="button" @click="sendTitle">Próximo Passo</button>
      </div>
      <div class="mancha-step">
        <img src="../../assets/img/element-2.png" alt="imagem" />
      </div>
    </div>
  </div>
</template>


<style scoped>
.padding-5px {
  padding: 7px;
}

#input-insta {
  height: 50px;
  font-size: 1.8rem;
  background-color: #2b2b2b;
  color: #feea2f;
  border-radius: 5px;
  outline: none;
  border: solid 0 #000;
  margin: 5%;
}
</style>
<script>
import router from "../../router";
import store from "../../store";

export default {
  name: "NuggetTitle",
  data() {
    return {
      inputTitle: "",
    };
  },
  created() {
    if (store.state.audioNugget && store.state.audioNugget.titleText) {
      // Step is already set, skip it
      router.push({
        name: "ChooseNuggetTemplate",
      });
      return;
    }
  },
  methods: {
    async sendTitle() {
      store.commit("mergeAudioNugget", {
        titleText: this.inputTitle,
      });
      router.push({
        name: "ChooseNuggetTemplate",
      });
    },
  },
};
</script>